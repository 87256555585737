import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import UserActions from "./UserActions";

const UserTable = ({ dbUser, users, deleteUser, handleOpenDialog }) => {
    const [nameFilter, setNameFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    
    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    };
    
    const handleRoleFilterChange = (event) => {
        setRoleFilter(event.target.value);
    };
    
    const filteredUsers = users.filter(user => {
        const nameMatch = user.name.toLowerCase().includes(nameFilter.toLowerCase()) || user.email?.toLowerCase().includes(nameFilter.toLowerCase());
        const roleMatch = roleFilter === '' || user.associations.some(assoc => assoc?.role?.name?.toLowerCase().includes(roleFilter.toLowerCase()));
        return nameMatch && roleMatch;
    });

    return (
        <div>
            <div style={{ marginBottom: '2em' }}>
                <TextField
                    label="Filter by Name/Email"
                    variant="standard"
                    value={nameFilter}
                    onChange={handleNameFilterChange}
                    style={{ marginRight: '2em' }}
                />
                <TextField
                    label="Filter by Role"
                    variant="standard"
                    value={roleFilter}
                    onChange={handleRoleFilterChange}
                    style={{ marginRight: '2em' }}
                />
            </div>
            <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
                <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Roles</TableCell>
                    <TableCell align="right">Joined</TableCell>
                    <TableCell align="right">Is Active</TableCell>
                    <TableCell align="right">Is Admin</TableCell>
                    <TableCell align="right">Is Superuser</TableCell>
                    <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((userItem) => (
                            <TableRow
                                key={userItem.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <div>{userItem.name}</div>
                                    <div>{userItem.email}</div>
                                </TableCell>
                                <TableCell align="right">{userItem.associations.map(assoc => assoc?.role?.name).join(', ')}</TableCell>
                                <TableCell align="right">{userItem.external_id ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="right"><Checkbox checked={userItem.is_active} disabled /></TableCell>
                                <TableCell align="right"><Checkbox checked={userItem.is_admin} disabled /></TableCell>
                                <TableCell align="right"><Checkbox checked={userItem.is_superuser} disabled /></TableCell>
                                <TableCell align="right"><UserActions 
                                    dbUser={dbUser} 
                                    userItem={userItem} 
                                    handleOpenDialog={handleOpenDialog} 
                                    deleteUser={deleteUser}
                                /></TableCell>
                            </TableRow>
                            ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7}>No users</TableCell>
                        </TableRow>
                    )}
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default UserTable;