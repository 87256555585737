// import { Typography } from "@mui/material";
import { useAuth } from "../../auth/AuthContext";
import { useProject } from "../../project/ProjectContext";
import { useEffect, useState } from "react";
import PresaleDetailsFormPart from "./PresaleDetailsFormPart";
import { Navigate } from "react-router-dom";

const data = [
    {
        name: 'product_link',
        label: 'Product Link',
        isLink: true,
    },
    {
        name: 'business_channel_key',
        label: 'Business Channel Link',
        isLink: true,
    },
    {
        name: 'dev_channel_key',
        label: 'Dev Channel Link',
        isLink: true,
    },
    {
        name: 'business_resources_link',
        label: 'Business Resources Link',
        isLink: true,
    },
    {
        name: 'dev_resources_link',
        label: 'Dev Resources Link',
        isLink: true,
    },
    {
        name: 'miro_board_link',
        label: 'Miro Board Link',
        isLink: true,
    },
    {
        name: 'design_board_link',
        label: 'Design Board Link',
        isLink: true,
    },
    {
        name: 'audit_check_link',
        label: 'Audit Check Link',
        isLink: true,
    },
    // {
    //     name: 'presale_started_at',
    //     label: 'Presale Started At',
    // },
    // {
    //     name: 'presale_ended_at',
    //     label: 'Presale Ended At',
    // },
    // {
    //     name: 'presale_status',
    //     label: 'Presale Status',
    //     isSelect: true,
    //     options: [
    //         'Not Started',
    //         'In Progress',
    //         'Completed',
    //     ],
    // },
    // {
    //     name: 'framework_current_step',
    //     label: 'Framework Current Step',
    //     isSelect: true,
    //     options: [
    //     ],
    // },
    {
        name: 'business_context',
        label: 'Business Context',
    },
    {
        name: 'architecture_context',
        label: 'Architecture Context',
    },
    // {
    //     name: 'budget',
    //     label: 'Budget',
    // },
    // {
    //     name: 'currency',
    //     label: 'Currency',
    // },
    // {
    //     name: 'offer',
    //     label: 'Offer',
    // },
    {
        name: 'language',
        label: 'Language',
        isSelect: true,
        options: [
            'English',
            'Polish',
        ],
    },
]
const PresaleDetails = () => {
    const { project } = useProject();
    const [projectDetails, setProjectDetails] = useState(null);
    const { user, dbUser } = useAuth();

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/presales/${project.id}/details`);
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const projectDetails = await response.json();
                setProjectDetails(projectDetails);
            } catch (error) {
                console.error('Failed to fetch project details:', error);
                // Handle or display error
            }
        };
        if (project && !projectDetails) {
            fetchProjectDetails();
        }
    }, [project])

    if (!project) {
        
        <Navigate to="/presales" />
    }

    console.log('Project Details:', projectDetails);
    return (
        <div>
        <h2>{project?.name}</h2>
        
        <h3>{project?.description}</h3>

        {data.map((item) => {
            console.log('Item:', item);
            console.log('Project Details Value:', projectDetails?.[item.name] );
            return (
            <PresaleDetailsFormPart 
                key={item.name}
                partName={item.name}
                partLabel={item.label} 
                partValue={projectDetails?.[item.name] || ''} 
                isLink={item?.isLink || false}
                isSelect={item?.isSelect || false}
                options={item?.options || []}
            />
            )}
        )}
        </div>
    );
}

export default PresaleDetails;