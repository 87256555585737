import React from 'react';
import { useAuth, decodeJWT } from '../auth/AuthContext';
import { Box, Button, Typography } from '@mui/material';

const UserDashboard = () => {
    const { user } = useAuth();
    
    const userData = decodeJWT(user.token);
    if (!userData) {
      return <p>Please <a href='/login'>log in</a> first</p>;
    }
    // const recentActivities = ['Posted a comment', 'Liked a post', 'Updated profile'];
    const recentActivities = [];
    
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Welcome back, {userData.name}!</Typography>
        {recentActivities.length > 0 && <><Typography variant="subtitle1" sx={{ mt: 1 }}>Recent Presales:</Typography>
        <ul>
          {recentActivities.map((activity, index) => (
            <li key={index}>{activity}</li>
          ))}
        </ul>
        </>}
        <Button variant="contained" color="primary" sx={{ mt: 2 }} href="/presales">
          Go to presales
        </Button>
      </Box>
    );
  };

const HomePage = () => {
    const { user } = useAuth();
    return (
        <div className="App">
            {user ? <UserDashboard /> : <p>Please <a href='/login'>log in</a> to see your dashboard.</p>}
        </div>
    );
};

export default HomePage;
