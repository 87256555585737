import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogTitle, DialogContent,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper
} from '@mui/material';
import PresaleRoleFormFields from './PresaleRoleFormFields';
import { useAuth, decodeJWT } from '../../auth/AuthContext';

const emptyPresaleRole = {
    id: null,
    name: '',
}

const PresaleRoleList = () => {
    const { user } = useAuth();
    const currentUser = decodeJWT(user.token);

    const [presaleRoles, setPresaleRoles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(emptyPresaleRole);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    }

    const savePresaleRole = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/presales-roles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formValues, created_by: currentUser.sub})
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setPresaleRoles([...presaleRoles, data]);
            setFormValues(emptyPresaleRole);
            setOpenDialog(false);
        } catch (error) {
            console.error('Failed to save presale role:', error);
        }
    }

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/presales-roles`)
            .then(response => response.json())
            .then(data => setPresaleRoles(data))
            .catch(error => console.error('Failed to fetch presale roles:', error));
    }, []);

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>Add Presale Role</Button>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add Presale Role</DialogTitle>
                <DialogContent>
                    <PresaleRoleFormFields formValues={formValues} handleChange={handleChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={savePresaleRole}>Save</Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Role Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {presaleRoles.map((presaleRole) => (
                            <TableRow key={presaleRole.id}>
                                <TableCell>{presaleRole.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PresaleRoleList;
