import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import PresaleChatFormFields from './PresaleChatFormFields';
import AudioRecorder from '../media/AudioRecorder';


function InformationDialog({ 
    openDialog, 
    setOpenDialog, 
    saveChat, 
    formValues, 
    handleChange, 
    handleFileChange,
    error,
    showMedia,
}) {
    // State to track the type of information
    const [infoType, setInfoType] = useState(formValues?.info_type ? formValues?.info_type : 'text');
    const isEditMode = (formValues?.id  || false) !== false;

    useEffect(() => {
        if (formValues?.info_type !== infoType) {
            setInfoType(formValues?.info_type);
        }
    }, [formValues, infoType]);

    // Function to handle changing the information type
    const handleInfoTypeChange = (event) => {
        setInfoType(event.target.value);
        handleChange({target: {name: 'info_type', value: event.target.value}});
    };

    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            {isEditMode ? <DialogTitle>Edit Information</DialogTitle> : <DialogTitle>Add Information</DialogTitle>}
            <DialogContent>
                <RadioGroup row value={infoType} onChange={handleInfoTypeChange}>
                    <FormControlLabel value="text" control={isEditMode ? <Radio disabled/> : <Radio />} label="Text" />
                    <FormControlLabel value="audio" control={isEditMode ? <Radio disabled/> : <Radio />} label="Audio" />
                    <FormControlLabel value="record" control={isEditMode ? <Radio disabled/> : <Radio />} label="Record" />
                    <FormControlLabel value="video" control={<Radio disabled/>} label="Video" />
                </RadioGroup>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {showMedia ? (<p>Start / Stop audio recording - {formValues.description} ...</p>) : (
                    <PresaleChatFormFields
                    infoType={infoType}
                    formValues={formValues}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    isEditMode={isEditMode}
                />
                )}
            </DialogContent>
                {showMedia ? (
                    <DialogActions>
                        <AudioRecorder chat_id={formValues?.id} />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                        <Button onClick={saveChat}>Save</Button>
                    </DialogActions>
                )}
        </Dialog>
    );
}

export default InformationDialog;
