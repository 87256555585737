import * as React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Icon, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const menuItems = [
    {
      text: 'Users',
      icon: 'group',
      path: '/users',
      permission: 'superuser',
    },
    {
      text: 'Jobs Configuration',
      icon: 'work_outline_outline',
      path: '/jobs-configuration',
      permission: 'superuser',
    },
    {
      text: 'Roles',
      icon: 'admin_panel_settings',
      path: '/roles',
      permission: 'admin',
    },
    {
      text: 'Presales',
      icon: 'alarm-on',
      path: '/presales',
    },
    {
      text: 'Picture Recognition',
      icon: 'camera',
      path: '/picture-recognition',
      permission: 'superuser',
    },
  ];

const SidebarMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { dbUser } = useAuth();
  
    if (!dbUser) {
      return null;
    }

    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setIsOpen(open);
    };
  
    const list = () => (
      <List>
        {menuItems.map(({text, icon, path, permission}) => {
          if (permission == 'superuser' && !dbUser?.is_superuser) {
            return null;
          }
          if (permission == 'admin' && !dbUser?.is_admin) {
            return null;
          }
          return (
            <ListItemButton key={text} component={Link} to={path}>
              <ListItemIcon>
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>          
          );
        })}
      </List>
    );
  
    return (
      <div>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor='left'
          open={isOpen}
          onClose={toggleDrawer(false)}
          onClick={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </div>
    );
}
  
export default SidebarMenu;
  