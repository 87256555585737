import React, { useState, useEffect } from "react";
import { Box, TextField, Switch, FormControlLabel, Autocomplete, Chip } from '@mui/material';

const UserFormFields = ({ formValues, handleChange, }) => {
    const [rolesOptions, setRolesOptions] = useState([]);
    useEffect(() => {
        const fetchRoles = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/presales-roles`);
            const data = await response.json();
            setRolesOptions(data);
        };

        fetchRoles();
    }, []);
    return (
        <>
        {/* User Name */}
            <TextField
                label="User Name"
                variant="outlined"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
                
            {/* User Email */}
            <TextField
                label="User Email"
                variant="outlined"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
                    
            {/* External ID */}
            <TextField
                label="External ID"
                variant="outlined"
                name="external_id"
                value={formValues.external_id}
                onChange={handleChange}
                disabled={formValues.id !== null}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
                        
            {/* Active Switch */}
            <Box component="div">
                <FormControlLabel
                    control={
                    <Switch
                        checked={formValues.is_active}
                        onChange={handleChange}
                        name="is_active"
                    />
                    }
                    label="Active"
                />
            </Box>             
            {/* Admin Switch */}
            <Box component="div">
                <FormControlLabel
                    control={
                    <Switch
                        checked={formValues.is_admin}
                        onChange={handleChange}
                        name="is_admin"
                    />
                    }
                    label="Admin"
                />
            </Box>
            {/* Superuser Switch */}
            <Box component="div">
                <FormControlLabel
                    control={
                    <Switch
                        checked={formValues.is_superuser}
                        onChange={handleChange}
                        name="is_superuser"
                    />
                    }
                    label="Superuser"
                />
            </Box>
            {/* Roles */}
            <Autocomplete
                multiple
                options={rolesOptions}
                getOptionLabel={(option) => option.name}
                value={formValues.roles}
                onChange={(event, newValue) => {
                    handleChange({
                        target: {
                            name: 'roles',
                            value: newValue,
                        },
                    });
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Roles"
                        placeholder="Select roles"
                        margin="normal"
                        fullWidth
                    />
                )}
            />
        </>
    );
}

export default UserFormFields;
