import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogTitle, DialogContent,
  Typography
} from '@mui/material';
import UserFormFields from './UserFormFields';
import { useAuth } from '../../auth/AuthContext';
import UserTable from './UserTable';

const emptyUser = {
    id: null,
    name: '',
    email: '',
    external_id: '',
    is_active: true,
    is_admin: false,
    is_superuser: false,
    associations: [],
    roles: [],
}

const UserList = () => {
  const { user, dbUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formValues, setFormValues] = useState(emptyUser);
  
  useEffect(() => {
    if (user) {
      const fetchUsers = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/users`);
        const data = await response.json();
        console.log(data);
        setUsers(data);
      };
      fetchUsers();
    }
  }, [user]);

  // if (!user || !dbUser) {
  //   return <Typography variant="h6">Please <a href='/login'>login</a> first</Typography>;
  // }

  // if (!dbUser?.is_superuser) {
  //   return <Typography variant="h6">You are not authorized to view this page</Typography>;
  // }

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormValues({
        ...formValues,
        [name]: type === 'checkbox' ? checked : value,
    });
  };

  const saveUser = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const method = formValues.id ? 'PUT' : 'POST';
      const url = formValues.id ? `${apiUrl}/users/${formValues.id}` : `${apiUrl}/users`;

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formValues, created_by: dbUser.id })
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      
      if (formValues.id) {
        setUsers(users.map(user => user.id === data.id ? data : user));
      } else {
        setUsers([...users, data]);
      }
      
      setFormValues(emptyUser);
      setOpenDialog(false);
      
    } catch (error) {
      console.error('Failed to save user:', error);
      // Handle or display error
    }
  };

  const deleteUser = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/users/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      setUsers(users.filter(user => user.id !== id));

    } catch (error) {
      console.error('Failed to delete user:', error);
      // Handle or display error
    }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      saveUser();
  };

  const handleOpenDialog = (user = emptyUser) => {
    // take role from each associations
    const formUser = { ...emptyUser, ...user, roles: user.associations.map(assoc => assoc?.role)};
    setFormValues(formUser);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
       <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog(emptyUser)}
        style={{ marginBottom: '1rem' }}
      >
        Add New User
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{formValues.id ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <UserFormFields formValues={formValues} handleChange={handleChange}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <UserTable dbUser={dbUser} users={users} deleteUser={deleteUser} handleOpenDialog={handleOpenDialog} />
    </>

  );
};

export default UserList;
