import React, { useState, useEffect } from 'react';
import { TableCell, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../../auth/AuthContext';
import { useProject } from '../../project/ProjectContext';

// Example component for a row in your table
const ChatActionsCell = ({ chat }) => {
    const [jobActions, setJobActions] = useState([]);
    const [jobConfigurations, setJobConfigurations] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [openJobConfigDialog, setOpenJobConfigDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { dbUser } = useAuth();
    const { project } = useProject();

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-configurations/context/chat`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('Failed to fetch configurations:', response.statusText);
                return [];
            }
        })
        .then((data) => {
            setJobConfigurations(data);
        })
        .catch((error) => console.error('Error fetching configurations:', error));

        fetch(`${apiUrl}/job-actions/source/${chat.id}/chat`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('Failed to fetch actions:', response.statusText);
                return [];
            }
        })
        .then((data) => setJobActions(data))
        .catch((error) => console.error('Error fetching actions:', error));
    }, [chat.id]);

    useEffect(() => {
        const jobActionsCompleted = jobActions.map((jobAction) => jobAction.status !== 'completed' ? 1 : 0).join('');
        if (jobActions.length > 0 && jobActionsCompleted > 0) {
            const apiUrl = process.env.REACT_APP_API_URL;
            fetch(`${apiUrl}/job-actions/source/${chat.id}/chat`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error('Failed to fetch actions:', response.statusText);
                    return [];
                }
            })
            .then((data) => {
                if (data.length > 0) {
                    setTimeout(() => {
                        setJobActions(data);
                    }, 3000);
                }
            })
            .catch((error) => console.error('Failed to fetch job actions:', error));
        }
    }, [jobActions, chat]);

    const handlePreview = (action) => {
        setSelectedAction(action);
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedAction(null);
    };

    const handleJobConfigSelection = () => {
        setOpenJobConfigDialog(true);
    }

    const handleRemoveAction = () => {
        if (!selectedAction) {
            console.error('No action selected');
            return;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-actions/${selectedAction.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('Failed to delete action:', response.statusText);
                return {};
            }
        })
        .then((data) => {
            console.log('Action deleted:', data);
            setJobActions(jobActions.filter((action) => action.id !== selectedAction.id));
            handleCloseDialog();
        })
        .catch((error) => console.error('Error deleting action:', error));
    }

    const runJob = (jobConfigId) => {
        const config = jobConfigurations.find((config) => config.id === jobConfigId);
        if (!config) {
            console.error('Invalid job configuration:', jobConfigId);
            return;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-actions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                presale_id: project.id, 
                created_by: dbUser.id,
                job_configuration_id: jobConfigId,
                sources_str: chat.id
            })
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('Failed to run job:', response.statusText);
                return [];
            }
        })
        .then((data) => {
            console.log('Job run:', data);
            setOpenJobConfigDialog(false);
            setJobActions([...jobActions, data]);
        })
        .catch((error) => console.error('Error running job:', error));
    }

    return (
        <TableCell>
            <Button onClick={handleJobConfigSelection} variant="contained" color="primary">
                Run new job
            </Button>
            <Dialog open={openJobConfigDialog} onClose={() => setOpenJobConfigDialog(false)}>
                <DialogTitle>Run new job</DialogTitle>
                <DialogContent>
                    <p>Select a job configuration to run:</p>
                    <List>
                        {jobConfigurations.map((config, index) => {
                            return <ListItemButton key={`job-config-${index}`} button onClick={() => runJob(config.id)}>
                                <ListItemText primary={config.name} />
                            </ListItemButton>
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <List>
                {jobActions.map((action, index) => {
                    if (action?.status === 'completed' || dbUser.is_superuser) {
                        return <ListItemButton key={`action-list-${index}`} onClick={() => handlePreview(action)}>
                            <ListItemText primary={`${action?.job_configuration?.name} [${action?.status}]`} />
                        </ListItemButton>
                    } else {
                        return <ListItem key={`action-list-${index}`}>
                            <ListItemText primary={`${action?.job_configuration?.name} [${action?.status}]`} />
                        </ListItem>
                    }
                })}
            </List>
            <Dialog open={openDialog} onClose={handleCloseDialog}>  
                <DialogTitle>{selectedAction?.job_configuration?.name}</DialogTitle>
                <DialogContent>
                {/* Render preview content here */}
                {selectedAction?.status !== 'completed' ? (
                    <p>Job is still running...</p>
                ) : (
                    <ReactMarkdown>
                        {selectedAction?.output}
                        {/* Additional preview content */}
                    </ReactMarkdown> 
                )} 
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveAction} color="secondary">
                        Remove
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </TableCell>
    );
};

export default ChatActionsCell;
