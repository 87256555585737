import React, { createContext, useContext, useEffect, useState } from 'react';

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);


export const ProjectProvider = ({ children }) => {
  const [project, setProject] = useState(null);

  const clearProject = () => {
    setProject(null);
  };

  useEffect(() => {
    if (!project) {
      const storedProject = sessionStorage.getItem('project');
      if (storedProject) {
        setProject(JSON.parse(storedProject));
      }
    }
  }, [project]);

  const selectProject = (project) => {
    sessionStorage.setItem('project', JSON.stringify(project));
    setProject(project);
  };

  return (
    <ProjectContext.Provider value={{ project, selectProject, clearProject }}>
      {children}
    </ProjectContext.Provider>
  );
};
