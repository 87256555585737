import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    FormLabel,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon, Link as LinkIcon } from '@mui/icons-material';
import { useProject } from '../../project/ProjectContext';
import { useAuth } from '../../auth/AuthContext';
import ReactMarkdown from 'react-markdown';

const PresaleDetailsFormPart = ({ partName, partLabel, partValue, isLink = false }) => {
    console.log('Part Value: ', partValue);
    const { project } = useProject();
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({[partName]: partValue});
    const { dbUser } = useAuth();

    useEffect(() => {
        setFormValues({
            [partName]: partValue,
        });
    }, [partValue]);

    const handleChange = (event) => {
        setFormValues({
            [partName]: event.target.value,
        });
    };

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleSaveClick = () => {
        setEditMode(false);
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/presales/${project.id}/details`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...formValues, created_by: dbUser.id}),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Failed to save project details:', error);
                // Handle or display error
            });
    };

    console.log('FormValues', formValues);
    return (
        <FormGroup row={true} style={{ marginBottom: '2em' }}>
            {editMode ? (
                <TextField
                    label={partLabel}
                    variant="outlined"
                    name={partName}
                    value={formValues[partName]}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSaveClick} color="primary">
                                    <SaveIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    margin="normal"
                />
            ) : (
                <>
                    <FormGroup>
                        <FormLabel>
                            {partLabel}
                            {partName !== 'architecture_context' && partName !== 'business_context' && <IconButton 
                                onClick={handleEditClick} 
                                color="primary" 
                                size="small"
                                style={{ marginLeft: '1em' }}
                            >
                                <EditIcon />
                            </IconButton>}
                        </FormLabel>
                        
                        {formValues[partName] ? (
                            <>
                                {isLink ? (<Typography variant="body1" component="a" href={formValues[partName]} target="_blank" rel="noopener noreferrer" style={{ marginRight: '1em' }}>
                                        <span>{formValues[partName]}</span>
                                    </Typography>
                                ) : (<Typography variant="body1" color="textSecondary" style={{ marginRight: '1em' }} fullWidth>
                                    {partName === 'architecture_context' || partName === 'business_context' ? (
                                        <ReactMarkdown>
                                            {formValues[partName]}
                                        </ReactMarkdown>   
                                    ) : (
                                        <span>{formValues[partName]}</span>
                                    )}
                                    
                                </Typography>
                                )}
                            </>
                        ) : (
                                <Typography variant="body1" color="textSecondary" style={{ marginRight: '1em' }}>
                                    <span>Not set</span>
                                </Typography>
                            )}
                    </FormGroup>
                    {/* ) : (
                        <TextField
                            label={partLabel}
                            variant="outlined"
                            name={partName}
                            value={formValues[partName] || ''}
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                        />
                    )} */}
                </>
            )}
        </FormGroup>
    );
};

export default PresaleDetailsFormPart;