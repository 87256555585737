import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';



function PictureRecognition() {
  const [picUrl, setPicUrl] = useState('');
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState(null);

  const handleSubmit = async (event) => {
    setResponse(null); // Clear previous response

    event.preventDefault(); // Prevent default form submission behavior

    // Replace with your actual API endpoint
    const apiUrl = 'http://0.0.0.0:5000/recognise-picture?pic_url=' + picUrl + '&question=' + question;

    try {
      const result = await fetch(apiUrl, {
        method: 'GET'
      });

      if (!result.ok) {
        throw new Error(`Error: ${result.statusText}`);
      }

      const data = await result.json();
      setResponse(data);
    } catch (error) {
      console.error('Failed to fetch:', error);
      setResponse({ error: 'Failed to fetch data. Check console for details.' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <TextField
            type="text"
            value={picUrl}
            onChange={(e) => setPicUrl(e.target.value)}
            required
            fullWidth
            name="picUrl"   
            label="Picture URL"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginBottom: '1rem' }}
        />
        <TextField 
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
            fullWidth
            name="question"
            label="Question"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginBottom: '1rem' }}
        />
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
        >
            Submit
        </Button>
       
      
      {response && response.message && (
        <div>
          <h3>Response:</h3>
          <div style={{maxWidth: "600px"}}>{response.message}</div>
          <div className='image' style={{ marginBottom: '1rem'}}>
            <img src={picUrl} alt='Preview' style={{maxWidth: "400px"}} />
          </div>
        </div>
      )}
      </form>
  );
}

export default PictureRecognition;
