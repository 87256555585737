import React from "react";
import { TextField } from '@mui/material';

const PresaleRoleFormFields = ({ formValues, handleChange, }) => {
    return (
        <>
        {/* User Name */}
            <TextField
                label="Role name"
                variant="outlined"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
        </>
    );
}

export default PresaleRoleFormFields;