import { Checkbox, FormControlLabel, FormGroup, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useProject } from '../../project/ProjectContext';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircle from '@mui/icons-material/CheckCircle';

const PresaleTable = ({ presales, handleOpenDialog, deletePresale }) => {
    const { project, selectProject } = useProject();
    const [nameFilter, setNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [isProjectFilter, setIsProjectFilter] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    
    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    };
    
    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    const handleIsProjectFilterChange = (event) => {
        setIsProjectFilter(event.target.checked);
    };

    const handleIsActiveFilterChange = (event) => {
        setIsActiveFilter(event.target.checked);
    };

    const onSelectPresale = (presale) => {
        if (project?.id !== presale.id) {
            selectProject(presale);
            return (<Navigate to="/presale-details" />);
        } else {
            selectProject(null);
        }
    };

    const filteredPresales = presales.filter(presale => {
        const nameMatch = presale.name.toLowerCase().includes(nameFilter.toLowerCase());
        const isProjectMatch = isProjectFilter ? presale.is_project : !presale.is_project;
        // const statusMatch = statusFilter === '' || presale.status === statusFilter;
        const isActiveMatch = isActiveFilter ? presale.is_active : !presale.is_active;
        return nameMatch && isProjectMatch && isActiveMatch;
    });

    return (
    <>
        <FormGroup row={true} style={{ marginBottom: '2em' }}>
            <TextField
                label="Filter by Name"
                variant="standard"
                value={nameFilter}
                onChange={handleNameFilterChange}
                style={{ marginRight: '2em' }}
            />
                {/* <TextField
                    label="Filter by Status"
                    variant="standard"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    style={{ marginRight: '2em' }}
                /> */}
            
            <FormControlLabel variant="standard" control={
                <Switch 
                    variant="standard"
                    value={isProjectFilter}
                    onChange={handleIsProjectFilterChange}
                    checked={isProjectFilter}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            } label="Projects" style={{ marginRight: '2em', marginTop: '1em' }} />
            <FormControlLabel variant="standard" control={
                <Switch
                    value={isActiveFilter}
                    onChange={handleIsActiveFilterChange}
                    checked={isActiveFilter}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            } label="Active" style={{ marginRight: '2em', marginTop: '1em' }} />
        </FormGroup>
        <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead> 
                <TableBody>
                    {filteredPresales.length > 0 ? (
                        filteredPresales.map((presale) => {
                            return (
                            <TableRow key={presale.id}> 
                                <TableCell>
                                    <IconButton onClick={() => onSelectPresale(presale)}>
                                        {project && presale.id === project.id ? <CheckCircle /> : <CheckCircleOutlineIcon />}
                                    </IconButton>
                                    </TableCell>
                                <TableCell>{presale.name}</TableCell>
                                <TableCell>{presale.creator?.name}</TableCell>
                                <TableCell>
                                    <Checkbox checked={presale.is_active} disabled />
                                </TableCell>
                                <TableCell>
                                    <Checkbox checked={presale.is_project} disabled />
                                </TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleOpenDialog(presale)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => deletePresale(presale.id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            )
                        }
                    )) : (
                        <TableRow>
                            <TableCell colSpan={6}>No presales found</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>)
}

export default PresaleTable;