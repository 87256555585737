import { Edit, Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const UserActions = ({ 
    dbUser, 
    userItem, 
    handleOpenDialog,
    deleteUser
}) => {
    return (
        <>
            {dbUser?.is_superuser && <>
                <IconButton color="primary" onClick={() => handleOpenDialog(userItem)}><Edit /></IconButton>
                { dbUser.id !== userItem.id && <IconButton color="secondary" onClick={() => deleteUser(userItem.id)}><Delete /></IconButton>}
            </>}
        </>
    );
}
export default UserActions;