import React from "react";
import { TextField } from '@mui/material';

const PresaleTeamFormFields = ({ formValues, handleChange, usersList, rolesList }) => {
    if (!usersList || !rolesList || usersList.length === 0 || rolesList.length === 0) {
        return null;
    }
    return (
        <>
            <TextField
                label="Member"
                variant="outlined"
                name="user_id"
                value={formValues.user_id}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                <option key="none" value="" disabled>
                    Member
                </option>
                {usersList.map((user) => (
                    <option key={user?.id} value={user?.id}>
                        {user?.name}
                    </option>
                ))}
            </TextField>
            <TextField
                label="Role"
                variant="outlined"
                name="presale_role_id"
                value={formValues.presale_role_id}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                <option key="0" value="" disabled>
                    Role
                </option>
                {rolesList.map((role) => (
                    <option key={role?.id} value={role?.id}>
                        {role?.name}
                    </option>
                ))}
            </TextField>
        </>
    );
}

export default PresaleTeamFormFields;