import { Button } from '@mui/material';
import {RecordVoiceOverOutlined, StopCircleOutlined} from '@mui/icons-material';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../auth/AuthContext';
import { useProject } from '../../project/ProjectContext';

const AudioRecorder = ({ chat_id = 'demo' }) => {
    const [ recordingRunning, setRecordingRunning ] = useState(false);
    const [ recordingTime, setRecordingTime ] = useState(0);
    const [ recorderState, setRecorderState ] = useState(null);
    const { dbUser } = useAuth();
    const { project } = useProject();
    const apiUrl = process.env.REACT_APP_WS_URL || 'ws://0.0.0.0:5000';

    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    const socketRef = useRef(null);
    const wakeLockRef = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
            if (wakeLockRef.current) {
                wakeLockRef.current.release().catch(err => console.error('Error releasing wake lock:', err));
            }
        };
    }, []);

    async function getAudioStream() {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const destination = audioContext.createMediaStreamDestination();

        // Capture system audio
        const systemStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: false,
                noiseSuppression: false,
                sampleRate: 44100,
            },
        });

        // Capture microphone audio
        const microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        const systemSource = audioContext.createMediaStreamSource(systemStream);
        const microphoneSource = audioContext.createMediaStreamSource(microphoneStream);

        // Create GainNode for system audio to increase its volume
        const systemGain = audioContext.createGain();
        systemGain.gain.value = 4; // Increase the gain (volume) for system audio, adjust as needed

        systemSource.connect(systemGain).connect(destination);
        microphoneSource.connect(destination);

        return destination.stream;
    }

    async function startRecording() {
        try {
            const wakeLock = await navigator.wakeLock.request('screen');
            wakeLockRef.current = wakeLock;
            console.log('Screen wake lock is active');

            const audioStream = await getAudioStream();
            streamRef.current = audioStream;
            const recorder = new MediaRecorder(audioStream);
            recorderRef.current = recorder;
            setRecorderState(recorder);

            const socket = new WebSocket(`${apiUrl}/api/ws/audio?user_id=${dbUser.id}&project_id=${project.id}&chat_id=${chat_id}`);
            socketRef.current = socket;

            socket.onopen = () => {
                setRecordingRunning(true);
                console.log('WebSocket is open now.');
                recorder.ondataavailable = (e) => {
                    if (e.data.size > 0) {
                        socket.send(e.data);
                    }
                };
                recorder.onstop = () => {
                    setRecordingRunning(false);
                    clearInterval(timerRef.current);
                    if (socketRef.current) {
                        socketRef.current.close();
                    }
                    console.log('Stream: ', streamRef.current);
                    if (streamRef.current) {
                        console.log('Stopping tracks: ', streamRef.current.getTracks());
                        streamRef.current.getTracks().forEach(track => track.stop());
                        streamRef.current = null;
                    }
                    console.log('Recording stopped');
                    window.location.reload();
                };
                recorder.onstart = () => {
                    console.log('Recording started');
                    setRecorderState(recorder);
                    setRecordingTime(0);
                    // Start the timer
                    timerRef.current = setInterval(() => {
                        setRecordingTime((prevTime) => prevTime + 1);
                    }, 1000);
                };
                recorder.start(1000); // Send data in 10-second intervals
            };
        } catch (error) {
            console.error('Error accessing audio streams:', error);
        }
    }

    function stopRecording() {
        if (wakeLockRef.current) {
            wakeLockRef.current.release().then(() => {
                console.log('Screen wake lock is released');
            }).catch((err) => {
                console.error(`Error releasing wake lock: ${err}`);
            });
        }
        console.log('Stopping recording');
        console.log('Recording time:', recordingTime);
        console.log('Recorder state:', recorderRef.current);
        // if (recorderState && recorderState.state !== 'inactive') {
        //     recorderState.stop();
        // }
        if (recorderRef.current && recorderRef.current.state !== 'inactive') {
            recorderRef.current.stop();
        }
        
    }

    if (!dbUser || !project) {
        return null;
    }

    return (
        <div>
            {!recordingRunning && <Button variant="contained"
                color="primary" onClick={startRecording}>
                <RecordVoiceOverOutlined />
                <div style={{ marginLeft: '1rem' }}>Start recording</div>
            </Button>}
            {recordingRunning && (
                <div>
                    <Button variant="outlined"
                    color="primary" onClick={stopRecording}>
                    <StopCircleOutlined />
                    <div style={{ marginLeft: '1rem' }}>Recording time: {recordingTime} seconds</div>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AudioRecorder;