import React, { useEffect, useState } from "react";
import { TextField, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, Hidden } from '@mui/material';
import { useAuth } from '../../auth/AuthContext';

const PresaleChatFormFields = ({ 
    infoType, 
    formValues, 
    handleChange, 
    handleFileChange,
    isEditMode = false
}) => {
    const { dbUser } = useAuth()
    const [presales, setPresales] = useState([]);

    useEffect(() => {
        if (dbUser && presales.length === 0) {
            /** get API my-presales */
            const apiUrl = process.env.REACT_APP_API_URL;
            var presalesUrl = `${apiUrl}/my-presales/${dbUser.id}`;
            if (dbUser?.is_admin) {
                presalesUrl = `${apiUrl}/presales`;
            }
            fetch(presalesUrl)
            .then((response) => response.json())
            .then((data) => setPresales(data))
            .catch((error) => console.error('Failed to fetch presales:', error));
        }
    }, [dbUser, presales]);

    if (!dbUser) {
        return <></>
    }

    return (
        <>
            {/* Chat Description */}
            <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
                
            {/* Date */}
            <TextField
                label="Date"
                variant="outlined"
                name="chat_date"
                value={formValues.chat_date}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />

            {/* Language */}
            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Language</FormLabel>
                <RadioGroup
                    row
                    aria-label="language"
                    name="language"
                    value={formValues.language}
                    onChange={handleChange}
                >
                    <FormControlLabel value="en" control={<Radio />} label="English" />
                    <FormControlLabel value="pl" control={<Radio />} label="Polish" />
                </RadioGroup>
            </FormControl>
            { isEditMode ? (
                <>
                    {/* select presale */}
                    <TextField
                        label="Presale"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        select
                        name="presale_id"
                        value={formValues.presale_id}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {/* select presale that id match */}
                        {presales.map((presale) => (
                            <option key={presale.id} value={presale.id}>
                                {presale.name}
                            </option>
                        )
                        )}
                    </TextField>
                    <TextField
                        label="Transcription"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        name="transcription"
                        value={formValues.transcription}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </>
            ) : (
                <>
                    {infoType === 'audio' && <TextField
                        type="file"
                        onChange={handleFileChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="audio_file"
                        label="Upload audio file for analysis"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />}
                    {infoType === 'video' && <TextField
                        type="file"
                        onChange={handleFileChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="video_file"
                        label="Upload video file for analysis"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />}
                    {infoType === 'text' && <TextField
                        label="Put here a text information that should be analysed"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        name="transcription"
                        value={formValues.transcription}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />}
                </>
            ) }
            
        </>
    );
}

export default PresaleChatFormFields;