import React, { useState, useEffect } from 'react';
import { TableCell, IconButton, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

// Example component for a row in your table
const ChatTranscriptionCell = ({ chat, onResendTranscription, onRecordNotFound }) => {
    const [rowData, setRowData] = useState(chat);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const fetchRowData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/chats/${chat.id}`);
            if (response.ok) {
                const data = await response.json();
                setRowData(data);
                // If the record now has a transcription, stop loading.
                if (data.transcription) {
                    setLoading(false);
                }
            } else {
                // Handle non-200 responses
                console.error('Failed to fetch row data:', response.statusText);
                console.log('Record not found:', chat.id);
                setLoading(false); // Consider stopping attempts if a permanent error occurs
                onRecordNotFound(chat.id);
            }
        } catch (error) {
            // Handle network errors
            console.error('Error fetching row data:', error);
            setLoading(false); // Consider stopping attempts if a network error occurs
        }
    };

    useEffect(() => {
        if (loading) {
            fetchRowData(); // Fetch immediately on mount

            const intervalId = setInterval(() => {
                if (!loading) {
                    clearInterval(intervalId); // Ensure the interval is cleared if loading has been set to false
                } else {
                    fetchRowData();
                }
            }, 3000); // Refresh every 3 seconds until transcription is obtained

            return () => clearInterval(intervalId); // Cleanup on unmount
        }
    }, [loading, chat.id]); // Rerun the effect if `loading` changes, or `rowId` changes


    // Function to handle the click on "Resend" button
    const handleResendClick = () => {
        onResendTranscription(chat.id);
    };

    // Function to handle opening the dialog
    const handleOpenDialog = () => {
        setOpen(true);
    };

    // Function to handle closing the dialog
    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <TableCell>
            {rowData.transcription === '' ? (
                <>
                    Pending...
                    {/* <Button
                        size="small"
                        variant="outlined"
                        onClick={handleResendClick}
                    >
                        Resend
                    </Button> */}
                    {chat.errorMessage && (
                        <div style={{ color: 'red' }}>{chat.errorMessage}</div>
                    )}
                </>
            ) : (
                <>
                    <IconButton onClick={handleOpenDialog} size="small">
                        <PreviewIcon />
                    </IconButton>
                    <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="transcription-preview" maxWidth="lg">
                        <DialogContent>
                            {/* Display the transcription inside the dialog */}
                            {rowData.transcription}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </TableCell>
    );
};

export default ChatTranscriptionCell;
