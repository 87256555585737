import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const decodeJWT = (token) => {
  try {
    const base64Url = token.split('.')[1]; // Get payload from the token
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode JWT", error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  // Initialize user state from sessionStorage, if available
  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [dbUser, setDbUser] = useState(() => {
    const storedDbUser = sessionStorage.getItem('dbUser');
    return storedDbUser ? JSON.parse(storedDbUser) : null;
  });

  const[lastMessage, setLastMessage] = useState(null);
  
  useEffect(() => {
    // Update sessionStorage whenever the user state changes
    if (user) {
      const userPayload = decodeJWT(user.token);
      const email = userPayload.email;
      const apiUrl = process.env.REACT_APP_API_URL;

      if (!dbUser) {
        fetch(`${apiUrl}/users/email/${email}`, {
          method: 'GET'
        }).then(
          response => response.json()
        ).then(
          data => {
            if (data?.detail  === 'User not found') {
              setLastMessage('User not found');
              setUser(null);
              setDbUser(null);
              sessionStorage.removeItem('user');
              sessionStorage.removeItem('dbUser');
              // create new user
              // const newUser = {
              //   email: email,
              //   name: `${userPayload.given_name} ${userPayload.family_name}`,
              //   external_id: userPayload.sub,
              //   is_active: true,
              //   is_admin: false,
              //   is_superuser: false
              // };
              // fetch(`${apiUrl}/users`, {
              //   method: 'POST',
              //   headers: {
              //     'Content-Type': 'application/json'
              //   },
              //   body: JSON.stringify(newUser)
              // }).then(
              //   response => response.json()
              // ).then(
              //   data => {
              //     console.log('User created:', data);
              //   }
              // ).catch(
              //   error => {
              //     console.error(error);
              //   }
              // );
            } else {
              if (data?.is_active === false) {
                setLastMessage('User is not active');
                setUser(null);
                setDbUser(null);
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('dbUser');
              } else {
                setDbUser(data);
                sessionStorage.setItem('dbUser', JSON.stringify(data));
                if (data.external_id !== userPayload.sub) {
                  // update external_id
                  fetch(`${apiUrl}/users/${data.id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                      ...data,
                      external_id: userPayload.sub 
                    })
                  }).then(
                    response => response.json()
                  ).then(
                    data => {
                      console.log('User updated:', data);
                      setDbUser(data);
                      sessionStorage.setItem('dbUser', JSON.stringify(data));
                      setLastMessage(null);
                    }
                  ).catch(
                    error => {
                      console.error(error);
                      setLastMessage('Failed to update user, try again later.');
                      setDbUser(null);
                      sessionStorage.removeItem('dbUser');
                      setUser(null);
                      sessionStorage.removeItem('user');
                    }
                  );
                } else {
                  setLastMessage(null);
                  setDbUser(data);
                  sessionStorage.setItem('dbUser', JSON.stringify(data));
                  console.log('User already exists:', data);
                }
              }
            }
          }
        ).catch(
          error => {
            console.error(error);
            setLastMessage('Failed to fetch user, try again later.');
            setDbUser(null);
            sessionStorage.removeItem('dbUser');
            setUser(null);
            sessionStorage.removeItem('user');
          }
        );
      }
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('dbUser', JSON.stringify(dbUser));
    } else {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('dbUser');
    }
  }, [user, dbUser]);

  const signIn = (googleUser) => {
    setUser(googleUser);
  };

  const signOut = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, dbUser, lastMessage, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
