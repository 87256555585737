import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RequireAuth from '../auth/RequireAuth';
import RequirePermissions from '../auth/RequirePermissions';
import LoginPage from '../auth/GoogleLogin';
import AppLayout from '../layout/AppLayout';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';
import PictureRecognition from '../pages/PictureRecognition';
import UsersPage from '../pages/UsersPage';
import PresaleRoleList from './presale-roles/PresaleRoleList';
import PresaleList from './presales/PresaleList';
import PresaleChats from './presales/PresaleChats';
import PresaleDetails from './presales/PresaleDetails';
import PresaleTeam from './presales/PresaleTeam';
import JobsConfigurationList from './jobs-configuration/JobsConfigurationList';
import PresaleJobs from './presales/PresaleJobs';
import AudioRecorder from './media/AudioRecorder';


const ProtectedPage = ({children}) => {
  return (
    <RequireAuth>
      <AppLayout>
        {children}
      </AppLayout>
    </RequireAuth>
  );
};

const SuperuserPage = ({children}) => {
  return (
    <RequireAuth>
      <AppLayout>
        <RequirePermissions requiredRole="superuser">
          {children}
        </RequirePermissions>
      </AppLayout>
    </RequireAuth>
  );
}

const AdminPage = ({children}) => {
  return (
    <RequireAuth>
      <AppLayout>
        <RequirePermissions requiredRole="admin">
          {children}
        </RequirePermissions>
      </AppLayout>
    </RequireAuth>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedPage><HomePage /></ProtectedPage>} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/users" element={<SuperuserPage><UsersPage /></SuperuserPage>} />
      <Route path="/jobs-configuration" element={<SuperuserPage><JobsConfigurationList /></SuperuserPage>} />
      <Route path="/roles" element={<AdminPage><PresaleRoleList /></AdminPage>} />
      <Route path="/presales" element={<ProtectedPage><PresaleList /></ProtectedPage>} />
      <Route path="/presale-chats" element={<ProtectedPage><PresaleChats /></ProtectedPage>} />
      <Route path="/picture-recognition" element={<SuperuserPage><PictureRecognition /></SuperuserPage>} />
      <Route path="/presale-details" element={<ProtectedPage><PresaleDetails /></ProtectedPage>} />
      <Route path="/presale-team" element={<ProtectedPage><PresaleTeam /></ProtectedPage>} />
      <Route path="/presale-jobs" element={<ProtectedPage><PresaleJobs /></ProtectedPage>} />
      <Route path="/media-player" element={<ProtectedPage><AudioRecorder /></ProtectedPage>} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
