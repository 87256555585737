import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState, useEffect } from "react";
import JobConfigurationFormFields from "./JobConfigurationFormFields";
import JobsConfigurationTable from "./JobsConfigurationTable";
import { useAuth } from "../../auth/AuthContext";

const emptyJobConfiguration = {
    name: '',
    prompt: 'Analyse given context to get essential informations.',
    created_by: null,
    job_context: 'presale',
    is_active: true,
    role: 'Friendly assistant',
    output_format: 'markdown',
    model: 'gpt-4o',
    human_text: '...',
};

const JobsConfigurationList = () => {
    const [jobConfigurations, setJobConfigurations] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(emptyJobConfiguration);
    const { dbUser } = useAuth();

    useEffect(() => {
        // fetch jobs configurations
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-configurations`)
        .then((response) => response.json())
        .then((data) => setJobConfigurations(data))
        .catch((error) => console.error('Failed to fetch jobs configurations:', error));
    }, []);

    const handleOpenDialog = (jobConfiguration = emptyJobConfiguration) => {
        setFormValues(jobConfiguration);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveJobConfiguration();
    };

    const saveJobConfiguration = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const method = formValues.id ? 'PUT' : 'POST';
        const url = formValues.id ? `${apiUrl}/job-configurations/${formValues.id}` : `${apiUrl}/job-configurations`;
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formValues, created_by: dbUser.id, is_active: formValues.is_active === "false" ? false : true}),
        })
        // check if status_code is 200
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to save job configuration');
            }
            return response.json();
        })
        .then((data) => {
            if (formValues.id) {
                setJobConfigurations(jobConfigurations.map(job => job.id === data.id ? data : job));
            } else {
                setJobConfigurations([...jobConfigurations, data]);
            }
            setFormValues(emptyJobConfiguration);
            handleCloseDialog();
        })
        .catch((error) => console.error('Failed to save job configuration:', error));
    }

    const deleteJobConfiguration = (jobConfiguration) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-configurations/${jobConfiguration.id}`, {
            method: 'DELETE',
        })
        .then(() => {
            setJobConfigurations(jobConfigurations.filter(job => job.id !== jobConfiguration.id));
        })
        .catch((error) => console.error('Failed to delete job configuration:', error));
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog(emptyJobConfiguration)}
                style={{ marginBottom: '1rem' }}
            >
                Add New Job Configuration
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{formValues.id ? 'Edit Job Configuration' : 'Add New Job Configuration'}</DialogTitle>
                <DialogContent>
                    <JobConfigurationFormFields formValues={formValues} handleChange={handleChange}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
            <JobsConfigurationTable 
                jobs={jobConfigurations} 
                handleOpenDialog={handleOpenDialog} 
                handleDelete={deleteJobConfiguration} 
            />
        </>
    );
};

export default JobsConfigurationList;