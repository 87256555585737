import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Toolbar,
  Typography,
  Box,
  IconButton,
  
} from '@mui/material';
import Chat from '@mui/icons-material/Chat';
import Insights from '@mui/icons-material/Insights';
import AutoStories from '@mui/icons-material/AutoStories';
import Diversity3 from '@mui/icons-material/Diversity3';
import { useProject } from '../project/ProjectContext';

const ProjectToolbar = () => {
    const { project } = useProject();
    const navigate = useNavigate();

    const projectDetails = () => {
        navigate(`/presale-details`);
    };

    const projectJobs = () => {
      navigate(`/presale-jobs`);
    };

    const projectChats = () => {
        navigate(`/presale-chats`);
    };

    const projectTeam = () => {
        navigate(`/presale-team`);
    };

    return (
        <>
          {project && (
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'flex-end', background: '#EEEEEE' }}>
              <Box sx={{ flexGrow: 1}} />
              <Typography sx={{ flexGrow: 1}} color="secondary" component="div">
                Selected Project: {project.name}
              </Typography>
              <Box sx={{ flexGrow: 1}}>
                <IconButton size="large" aria-label="project details" color="primary" onClick={projectDetails}>
                    <AutoStories />
                </IconButton>
                <IconButton size="large" aria-label="project jobs" color="primary" onClick={projectJobs}>
                    <Insights />
                </IconButton>
                <IconButton size="large" aria-label="project communication" color="primary" onClick={projectChats}>  
                    <Chat />
                </IconButton>
                <IconButton size="large" aria-label="project team" color="primary" onClick={projectTeam}>  
                    <Diversity3 />
                </IconButton>
              </Box>
            </Toolbar>
          )}
        </>
    );
}

export default ProjectToolbar;
