import React from 'react';
import { Box, IconButton, Avatar, Tooltip, Menu, MenuItem } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useAuth, decodeJWT } from '../../auth/AuthContext';

const UserTopMenu = () => {
  const { user, dbUser, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    signOut();
  };

  if (!user || !user.token || !dbUser) {
    return null;
  }

  const userData = decodeJWT(user.token);
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {user && (
        <>
            <Tooltip title={dbUser.email} arrow>
                <Avatar alt={user.name} src={userData.picture} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }} />
            </Tooltip>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>
                <IconButton size="large" aria-label="logout current user" color="primary" onClick={handleLogout}>
                    <LogoutIcon />
                </IconButton>
                Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default UserTopMenu;