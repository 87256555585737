// import { useEffect} from 'react';
import { useAuth } from './AuthContext';

const RequirePermissions = ({requiredRole, children }) => {
    const { user, dbUser } = useAuth();
    if (!user || !dbUser) {
        return null;
    }
    if (requiredRole === 'superuser' && !dbUser?.is_superuser) {
        return "You need Superuser Role to view this page";
    }
        if (requiredRole === 'admin' && !dbUser?.is_admin) {
        return "You need Admin Role to view this page";
    }
    return children;
};

export default RequirePermissions;
