import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import { useAuth } from "../../auth/AuthContext";

const PresaleJobActionsTable = ({ jobActions, handleSelectJobAction, handleDeleteJobAction }) => {
    const [ nameFilter, setNameFilter ] = useState('');
    const [ statusFilter, setStatusFilter ] = useState('');
    const [ openDialog, setOpenDialog ] = useState(false);
    const [ selectedAction, setSelectedAction ] = useState(null);
    const { dbUser } = useAuth();

    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    }

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    }

    const filteredJobActions = jobActions.filter(jobAction => {
        const nameMatch = jobAction.name.toLowerCase().includes(nameFilter.toLowerCase());
        const statusMatch = statusFilter === '' || jobAction.status === statusFilter;
        return nameMatch && statusMatch;
    });

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedAction(null);
    }

    const handleJobActionResults = (jobAction) => {
        setSelectedAction(jobAction);
        setOpenDialog(true);
    }

    const handleRemoveAction = () => {
        if (!selectedAction) {
            console.error('No action selected');
            return;
        }
        handleDeleteJobAction(selectedAction.id);
        setOpenDialog(false);
    }

    return (
        <>
            <FormGroup row={true} style={{ marginBottom: '2em' }}>
                <TextField
                    label="Filter by Name"
                    variant="standard"
                    value={nameFilter}
                    onChange={handleNameFilterChange}
                    style={{ marginRight: '2em' }}
                />
                <TextField
                    label="Filter by Status"
                    variant="standard"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    style={{ marginRight: '2em' }}
                />
            </FormGroup>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredJobActions.map((jobAction) => (
                            <TableRow key={jobAction.id}>
                                <TableCell>{jobAction.name}</TableCell>
                                <TableCell>{jobAction.status}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSelectJobAction(jobAction)}
                                    >
                                        Details
                                    </Button>
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: '1em' }}
                                            onClick={() => handleJobActionResults(jobAction)}
                                        >
                                            View Results
                                        </Button>
                                        <Dialog open={openDialog} onClose={handleCloseDialog}>  
                                            <DialogTitle>{selectedAction?.job_configuration?.name}</DialogTitle>
                                            <DialogContent>
                                                {/* Render preview content here */}
                                                {selectedAction?.status !== 'completed' ? (
                                                    <p>Job is still running...</p>
                                                ) : (
                                                    <ReactMarkdown>
                                                        {selectedAction?.output}
                                                        {/* Additional preview content */}
                                                    </ReactMarkdown> 
                                                )} 
                                            </DialogContent>
                                            <DialogActions>
                                                {dbUser.is_superuser && <Button onClick={handleRemoveAction} color="secondary">
                                                    Remove
                                                </Button>}
                                                <Button onClick={handleCloseDialog} color="primary">
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default PresaleJobActionsTable;
