import React, { useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    // Dialog, DialogActions, DialogTitle, DialogContent,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper,
    Checkbox
} from '@mui/material';
import { useAuth } from '../../auth/AuthContext';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutline from '@mui/icons-material/EditRounded';
import { useProject } from '../../project/ProjectContext';
import ChatTranscriptionCell from './ChatTranscriptionCell';
import ChatActionsCell from './ChatActionsCell';
import InformationDialog from './InformationDialog';

const emptyChat = {
    id: null,
    description: '',
    chat_date: '',
    language: 'en',
    info_type: 'text',
}

const PresaleChats = () => {
    const { user, dbUser } = useAuth();
    const { project } = useProject();

    const [chats, setChats] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(emptyChat);
    const [error, setError] = useState(null);
    const [showMedia, setShowMedia] = useState(false);
    
    useEffect(() => {
        const fetchChats = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/chats?presale_id=${project.id}`);
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const chats = await response.json();
                setChats(chats);
            } catch (error) {
                console.error('Failed to fetch chats:', error);
            }
        };
        if (project) {
            fetchChats();
        }
    }, [project]);

    const handleChange = (e) => {
        setError(null);
        const { name, value, checked, type } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormValues({
            ...formValues,
            file: file,
        });
    };

    const saveChat = async () => {
        console.log('Saving chat:', formValues);
        if (!formValues.description) {
            setError('Description is required');
            return;
        }
        setError(null);
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const formData = new FormData();
            const file_name = (!formValues.file) ? '' : formValues.file.name;
            const file_size = (!formValues.file) ? 0 : formValues.file.size;
            const file_type = (!formValues.file) ? '' : formValues.file.type;
            const transcription = (formValues.transcription) ? formValues.transcription : '';
            const type = (formValues.info_type) ? formValues.info_type : 'text';  
            var method = 'PUT';
            var url = `${apiUrl}/chats`;
            var editMode = true;  
            
            if (formValues?.id) {
                formData.append('chat', JSON.stringify({
                    description: formValues.description,
                    chat_date: formValues.chat_date,
                    created_by: dbUser.id,
                    language: formValues.language,
                    presale_id: formValues.presale_id,
                    transcription: formValues.transcription,
                    info_type: type,
                    file_name: null,
                    file_type: null,
                    file_size: null,
                }));
                url = `${apiUrl}/chats/${formValues.id}`;
            } else {
                formData.append('chat', JSON.stringify({
                    description: formValues.description,
                    chat_date: formValues.chat_date,
                    language: formValues.language,
                    created_by: dbUser.id,
                    presale_id: project.id,
                    file_name: file_name,
                    file_size: file_size,
                    file_type: file_type,
                    transcription: transcription,
                    info_type: type
                }));
                if (type === 'audio' || type === 'video') {
                    formData.append('file', formValues.file);
                }
                url = `${apiUrl}/chats/${type}`;
                method = 'POST';
                editMode = false;
            }
            const response = await fetch(url, {
                method: method,
                body: formData
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            if (editMode) {
                // @TODO - Handle success (e.g., show a message, redirect, etc.)    
                if (data.presale_id !== project.id) {
                    // remove chat from list if it belongs to another project
                    setChats((currentChats) =>
                        currentChats.filter((chat) => chat.id !== data.id)
                    );
                } else {
                    setChats((currentChats) =>
                        currentChats.map((chat) =>
                            chat.id === data.id ? data : chat
                        )
                    );
                }
            } else {
                // @TODO - Handle success (e.g., show a message, redirect, etc.)
                setChats([...chats, data]);
            }
            if (formValues?.info_type === 'record') { 
                console.log(data);
                setShowMedia(true);
                setFormValues(data);
                return;
            } else {
                setFormValues(emptyChat);
                setOpenDialog(false);
            }
        } catch (error) {
            console.error('Failed to save chat:', error);
            // Handle or display error
        }
    };

    const resendTranscription = async (chat_id) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/chats/${chat_id}/resend-transcription-request`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setChats((currentChats) =>
                currentChats.map((chat) =>
                    chat.id === chat_id ? { ...chat, errorMessage: '' } : chat
                )
            );
            console.log(data);
        } catch (error) {
            setChats((currentChats) =>
                currentChats.map((chat) =>
                    chat.id === chat_id ? { ...chat, errorMessage: `Request not sent - ${error.message}`} : chat
                )
            );
            console.error('Failed to resend transcription:', error);
            // Handle or display error
        }
    }

    const removeRow = (chat_id) => {
        console.log('Removing row:', chat_id);
        setChats((currentChats) =>
            currentChats.filter((chat) => chat.id !== chat_id)
        );
    }

    const removeChat = async (chat_id) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/chats/${chat_id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setChats((currentChats) =>
                currentChats.filter((chat) => chat.id !== chat_id)
            );
        } catch (error) {
            console.error('Failed to remove chat:', error);
            // Handle or display error
        }
    }

    const handleEdit = (chat) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/chats/${chat.id}`)
            .then((response) => response.json())
            .then((data) => {
                setFormValues({
                    ...data,
                    chat_date: data.chat_date.split('T')[0],
                });
                setOpenDialog(true);
            })
            .catch((error) => {
                console.error('Failed to fetch chat:', error);
                // Handle or display error
            });
    }

    const handleOpenNewChat = () => {
        setFormValues(emptyChat);
        setOpenDialog(true);
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenNewChat}
                style={{ marginBottom: '1rem' }}
            >
                + New information
            </Button>
            <InformationDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                saveChat={saveChat}
                formValues={formValues}
                handleChange={handleChange}
                handleFileChange={handleFileChange}
                error={error}
                setError={setError}
                showMedia={showMedia}
                setShowMedia={setShowMedia}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Information Date</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Transcription</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chats.map((chat, index) => (
                            <TableRow key={`row-${index}`}>
                                <TableCell>{chat.description}</TableCell>
                                <TableCell>{chat.chat_date}</TableCell>
                                <TableCell>{chat.language}</TableCell>
                                <ChatTranscriptionCell
                                    key={`transcription-${chat.id}`}
                                    chat={chat}
                                    onResendTranscription={resendTranscription}
                                    onRecordNotFound={removeRow}
                                />
                                <ChatActionsCell
                                    key={`actions-${chat.id}`}
                                    chat={chat}
                                />
                                <TableCell>
                                    {/* edit */}
                                    <IconButton
                                        onClick={() => handleEdit(chat)}
                                        size="small"
                                    >
                                        <EditOutline />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => removeChat(chat.id)}
                                        size="small"
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PresaleChats;
