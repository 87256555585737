import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContent,
} from '@mui/material';
import { useProject } from "../../project/ProjectContext";
import PresaleTeamFormFields from "./PresaleTeamFormFields";
import { Diversity3Outlined } from "@mui/icons-material";
import PresaleTeamTable from "./PresaleTeamTable";

const emptyMember = {
    id: null,
    user_id: "",
    presale_role_id: "",
    presale_id: "",
}

const PresaleTeam = () => {
    const { project } = useProject();
    const [team, setTeam] = useState([]);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(emptyMember);

    useEffect(() => {
        if (project) {
            const apiUrl = process.env.REACT_APP_API_URL;
            fetch(`${apiUrl}/users`)
                .then((response) => response.json())
                .then((data) => setUsers(data))
                .catch((error) => console.error('Failed to fetch users:', error));
            fetch(`${apiUrl}/presales-roles`)
                .then((response) => response.json())
                .then((data) => setRoles(data))
                .catch((error) => console.error('Failed to fetch roles:', error));
        
            fetch(`${apiUrl}/presales/${project.id}/team`)
                .then((response) => response.json())
                .then((data) => setTeam(data))
                .catch((error) => console.error('Failed to fetch team:', error));
        }
    }, [project]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleOpenDialog = (member = emptyMember) => {
        setFormValues(member);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const saveMember = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const method = formValues.id ? 'PUT' : 'POST';
            const url = formValues.id ? `${apiUrl}/team/${formValues.id}/` : `${apiUrl}/team`;
            if (formValues.id === null) {
                delete formValues.id;
            }    
            formValues.presale_id = project.id;

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formValues }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();

            if (formValues.id) {
                setTeam(team.map(member => member.id === data.id ? data : member));
            } else {
                setTeam([...team, data]);
            }

            setFormValues(emptyMember);
            setOpenDialog(false);
        } catch (error) {
            console.error('Failed to save member:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveMember();
    };

    const deleteMember = async (id) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/team/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            setTeam(team.filter(member => member.id !== id));

        } catch (error) {
            console.error('Failed to delete member:', error);
        }
    };

    if (!project) {
        return <></>
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog(emptyMember)}
                style={{ marginBottom: '1rem' }}
            >
                + New Team Member
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{formValues.id ? 'Edit Team Member' : 'Add New Team Member'}</DialogTitle>
                <DialogContent>
                    <PresaleTeamFormFields formValues={formValues} handleChange={handleChange} usersList={users} rolesList={roles} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
            <PresaleTeamTable team={team} handleOpenDialog={handleOpenDialog} deleteMember={deleteMember} />
        </div>
    );
};

export default PresaleTeam;
