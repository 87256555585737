import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TopBar from './TopBar';
import Theme from './ThemeProvider';

const AppLayout = ({ children }) => {
  return (
    <Theme>
      <Box sx={{ flexGrow: 1 }}>
        <TopBar />
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          {children}
        </Container>
      </Box>
    </Theme>
  );
};

export default AppLayout;