import React from "react";
import { Box, TextField, Switch, FormControlLabel } from '@mui/material';

const PresaleFormFields = ({ formValues, handleChange, usersList }) => {
    return (
        <>
        {/* Presale Name */}
            <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
                
            {/* Description */}
            <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
            />
            {/* Created By */}
            <TextField
                label="Created By"
                variant="outlined"
                name="created_by"
                value={formValues.created_by}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                <option key="0" value="" disabled>
                    Select User
                </option>
                {usersList.map((user) => (
                    <option key={user?.id} value={user?.id}>
                        {user?.name}
                    </option>
                ))}
            </TextField>
                    
            {/* Active Switch */}
            <Box component="div">
                <FormControlLabel
                    control={
                    <Switch
                        checked={formValues.is_active}
                        onChange={handleChange}
                        name="is_active"
                    />
                    }
                    label="Active"
                />
            </Box>             
            {/* Project Switch */}
            <Box component="div">
                <FormControlLabel
                    control={
                    <Switch
                        checked={formValues.is_project}
                        onChange={handleChange}
                        name="is_project"
                    />
                    }
                    label="Project"
                />
            </Box>
        </>
    );
}

export default PresaleFormFields;