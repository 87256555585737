import { FormGroup, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useProject } from "../../project/ProjectContext";
import { Delete, Diversity3Outlined, Edit } from "@mui/icons-material";
import { useState } from "react";

const PresaleTeamTable = ({ team, handleOpenDialog, deleteMember, showFilter = true, showActions = true }) => {
    const { project } = useProject();
    const [nameFilter, setNameFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');

    const handleNameFilterChange = (event) => { 
        setNameFilter(event.target.value);
    }

    const handleRoleFilterChange = (event) => {
        setRoleFilter(event.target.value);
    }

    const filteredTeam = team.filter(member => {
        const nameMatch = member.user.name.toLowerCase().includes(nameFilter.toLowerCase());
        const roleMatch = member.presale_role.name.toLowerCase().includes(roleFilter.toLowerCase());
        return nameMatch && roleMatch;
    });
    
    return (
        <>
        <h2><Diversity3Outlined /> {project.name} Team</h2>
        {showFilter && <FormGroup row={true} style={{ marginBottom: '2em' }}>
            <TextField
                label="Filter by Name"
                variant="standard"
                value={nameFilter}
                onChange={handleNameFilterChange}
                style={{ marginRight: '2em' }}
            />
            <TextField
                label="Filter by Role"
                variant="standard"
                value={roleFilter}
                onChange={handleRoleFilterChange}
            />
        </FormGroup>}
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Member</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredTeam.map((member) => (
                        <TableRow key={member?.id}>
                            <TableCell>{member?.user?.name}</TableCell>
                            <TableCell>{member?.presale_role?.name}</TableCell>
                            <TableCell>
                                {showActions && <>
                                    <IconButton color="primary" onClick={() => handleOpenDialog(member)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => deleteMember(member.id)}>
                                        <Delete />
                                    </IconButton>
                                </>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default PresaleTeamTable;