import { TextField } from "@mui/material";

export const jobContextOptions = [
    { value: 'presale', label: 'User selects many records' },
    { value: 'chat', label: 'Available for single record' },
];

const JobConfigurationFormFields = ({ formValues, handleChange }) => {
    return (
        <form noValidate autoComplete="off">
            <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                label="Job Context"
                variant="outlined"
                name="job_context"
                value={formValues.job_context}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                {jobContextOptions.map((option) => (
                    <option key={`job_context.${option.value}`} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>
            <TextField
                label="Role"
                name="role"
                value={formValues.role}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                label="Prompt"
                name="prompt"
                value={formValues.prompt}
                onChange={handleChange}
                fullWidth
                margin="normal"
                rows={4}
                multiline
            />
            <TextField
                label="Model"
                name="model"
                value={formValues.model}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                <option key="model.gpt-4o" value="gpt-4o">
                    gpt-4o
                </option>
                <option key="model.gpt-4-turbo" value="gpt-4-turbo">
                    gpt-4-turbo
                </option>
                <option key="model.gpt-3.5-turbo" value="gpt-3.5-turbo">
                    gpt-3.5-turbo
                </option>
            </TextField>

            <TextField
                label="Human Text"
                name="human_text"
                value={formValues.human_text}
                onChange={handleChange}
                fullWidth
                margin="normal"
                rows={4}
                multiline
            />
            <TextField
                label="Output Format"
                name="output_format"
                value={formValues.output_format}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                label="Is Active"
                name="is_active"
                value={formValues.is_active}
                onChange={handleChange}
                select
                SelectProps={{
                    native: true,
                }}
                fullWidth
                required
                margin="normal"
            >
                <option key="is_active.true" value={true}>
                    Yes
                </option>
                <option key="is_active.false" value={false}>
                    No
                </option>
            </TextField>
        </form>
    );
}

export default JobConfigurationFormFields;