import React, {useContext} from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../logo.png';
import { AuthContext } from '../auth/AuthContext'; 
import SidebarMenu from './SidebarMenu';
import ProjectToolbar from './ProjectToolbar';
import UserTopMenu from '../components/users/UserTopMenu';

const Logo = () => {
  return (
    <img src={logo} alt="Logo" style={{ maxHeight: 32, marginRight: 20 }} />
  );
};

const TopBar = () => {
    const { signOut, user } = useContext(AuthContext);
    return (
      <AppBar position="static"  sx={{ bgcolor: 'grey.200' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              component="div"
              sx={{ flexGrow: 1}}
            >
              {user && (
                <SidebarMenu /> 
              )}
            </Typography>
            <Box sx={{ flexGrow: 1}}>
              <a href="/" style={{ textDecoration: 'none' }}>
              <Logo />
              </a>
            </Box>
            {user && <UserTopMenu />}
          </Toolbar>
          <ProjectToolbar />
        </Container>
      </AppBar>
    );
};

export default TopBar;
