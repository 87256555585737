import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(91,33,182,100)',
      },
      secondary: {
        main: '#282c34',
      },
      error: {
        main: '#ff1744', // Example error color
      },
      // You can also customize the background, text colors, etc.
      background: {
        default: '#f7f6f3', // Example background color
        paper: '#ffffff',
        tableHeader: '#aaaaaa',
      },
      text: {
        primary: '#333333',
        secondary: '#666666',
      },
    },
  });

const Theme = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

export default Theme;
