import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog, DialogActions, DialogTitle, DialogContent,
} from '@mui/material';
import PresaleFormFields from './PresaleFormFields';
import { useAuth } from '../../auth/AuthContext';
import PresaleTable from './PresaleTable';
import { useProject } from '../../project/ProjectContext';


const emptyPresale = {
    id: null,
    name: '',
    description: '',
    is_active: true,
    is_project: false,
    created_by: null,
    creator: null,
    team: [],
}

const PresaleList = () => {
    const { user, dbUser } = useAuth();  
    const [presales, setPresales] = useState([]);
    const [users, setUsers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(emptyPresale);
    const { project, selectProject } = useProject();
    
    useEffect(() => {
        if (user && dbUser) {
            const apiUrl = process.env.REACT_APP_API_URL;
            var presalesUrl = `${apiUrl}/my-presales/${dbUser.id}`;
            if (dbUser?.is_admin) {
                presalesUrl = `${apiUrl}/presales`;
            }
            fetch(presalesUrl)
            .then((response) => response.json())
            .then((data) => setPresales(data))
            .catch((error) => console.error('Failed to fetch presales:', error));
        }
        if (dbUser?.is_superuser && users.length === 0) {
            fetch(`${process.env.REACT_APP_API_URL}/users`)
            .then((response) => response.json())
            .then((data) => setUsers(data))
            .catch((error) => console.error('Failed to fetch users:', error));
        }
        if (!dbUser?.is_superuser && presales.length === 0) {
            setUsers([dbUser]);
        }
    }, [user, dbUser]);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleOpenDialog = (presale = emptyPresale) => {
        setFormValues(presale);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const savePresale = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const method = formValues.id ? 'PUT' : 'POST';
            const url = formValues.id ? `${apiUrl}/presales/${formValues.id}` : `${apiUrl}/presales`;
            // console.log('Form Values: ', formValues);
            if (!formValues.created_by) {
                formValues.created_by = dbUser.id;
            }
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formValues })
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();

            if (formValues.id) {
                setPresales(presales.map(presale => presale.id === data.id ? data : presale));
                if (project.id === data.id) {
                    selectProject(data);
                }
            } else {
                setPresales([...presales, data]);
            }
            setFormValues(emptyPresale);
            setOpenDialog(false);
        } catch (error) {
            console.error('Failed to save presale:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        savePresale();
    };

    const deletePresale = async (id) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/presales/${id}`, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                }
            });
        
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
        
            setPresales(presales.filter(presale => presale.id !== id));
            if (project.id === id) {
                selectProject(null);
            }
    
        } catch (error) {
            console.error('Failed to delete presale:', error);
            // Handle or display error
        }
    };

    return (<>
       <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog(emptyPresale)}
        style={{ marginBottom: '1rem' }}
      >
        Add New Presale
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{formValues.id ? 'Edit Presale' : 'Add New Presale'}</DialogTitle>
        <DialogContent>
          <PresaleFormFields formValues={formValues} handleChange={handleChange} usersList={users}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <PresaleTable
        dbUser={dbUser} 
        users={users}
        presales={presales} 
        deletePresale={deletePresale} 
        handleOpenDialog={handleOpenDialog} />
    </>);
}

export default PresaleList;
