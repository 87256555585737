import { Delete, Edit } from "@mui/icons-material";
import { FormControlLabel, FormGroup, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";

const JobsConfigurationFilteredTable = ({ jobs, handleOpenDialog, handleDelete }) => {
    return (
        <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Context</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Format</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead> 
                <TableBody>
                    {jobs.length > 0 ? (
                        jobs.map((job) => {
                            return (
                            <TableRow key={job.id}>
                                <TableCell>{job.name}</TableCell>
                                <TableCell>{job.job_context}</TableCell>
                                <TableCell>{job.model}</TableCell>
                                <TableCell>{job.output_format}</TableCell>
                                <TableCell>{job.is_active ? 'Yes' : 'No'}</TableCell>
                                
                                <TableCell align="right">
                                    <IconButton onClick={() => handleOpenDialog(job)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(job)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6}>No jobs found</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const JobsConfigurationTable = ({ jobs, handleOpenDialog, handleDelete }) => {
    const [nameFilter, setNameFilter] = useState('');
    const [jobContextFilter, setJobContextFilter] = useState('');
    const [modelFilter, setModelFilter] = useState('');
    const [isActiveFilter, setIsActiveFilter] = useState(true);

    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    }

    const handleJobContextFilterChange = (event) => {
        setJobContextFilter(event.target.value);
    }

    const handleModelFilterChange = (event) => {
        setModelFilter(event.target.value);
    }

    const handleIsActiveFilterChange = (event) => {
        setIsActiveFilter(event.target.checked);
    }

    const filteredJobs = jobs.length > 0 ? jobs.filter(job => {
        const nameMatch = job.name.toLowerCase().includes(nameFilter.toLowerCase());
        const jobContextMatch = job.job_context.toLowerCase().includes(jobContextFilter.toLowerCase());
        const modelMatch = job.model.toLowerCase().includes(modelFilter.toLowerCase());
        const isActiveMatch = isActiveFilter ? job.is_active : !job.is_active;
        return nameMatch && jobContextMatch && modelMatch && isActiveMatch;
    }) : [];

    return (
        <>
            <FormGroup row={true} style={{ marginBottom: '2em' }}>
                <TextField
                    label="Filter by Name"
                    variant="standard"
                    value={nameFilter}
                    onChange={handleNameFilterChange}
                    style={{ marginRight: '2em' }}
                />
                <TextField
                    label="Filter by Context"
                    variant="standard"
                    value={jobContextFilter}
                    onChange={handleJobContextFilterChange}
                    style={{ marginRight: '2em' }}
                />
                <TextField
                    label="Filter by Model"
                    variant="standard"
                    value={modelFilter}
                    onChange={handleModelFilterChange}
                    style={{ marginRight: '2em' }}
                />
                <FormControlLabel variant="standard" control={
                    <Switch
                        variant="standard"
                        value={isActiveFilter}
                        onChange={handleIsActiveFilterChange}
                    />
                } label="Active" />
            </FormGroup>
            <JobsConfigurationFilteredTable 
                jobs={filteredJobs}
                handleOpenDialog={handleOpenDialog}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default JobsConfigurationTable;
