import React, { useEffect } from 'react';
import TopBar from '../layout/TopBar';
import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

function LoginPage() {
    const location = useLocation();
    console.log(location);
    useEffect(() => {
        // Initialize Google Identity Services
        window.google?.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
        });

        // Render the Google sign-in button
        window.google?.accounts.id.renderButton(
            document.getElementById("googleSignInButton"),
            { theme: "outline", size: "large" }
        );

        // Check if user is already logged in
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            const params = new URLSearchParams(location.search);
            const redirect = params.get('redirect');
            window.location.href = redirect ? redirect : '/';
        }
    }, [location]);

    const handleCredentialResponse = (response) => {
        const user = { token: response.credential };
        sessionStorage.setItem('user', JSON.stringify(user));
        const params = new URLSearchParams(location.search);
        const redirect = params.get('redirect');
        window.location.href = redirect ? redirect : '/';
    };

    return (
        <div className="App">
            <TopBar />
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                width: '50%', // You can adjust this or make it responsive using breakpoints
            }}>
                <div id="googleSignInButton"></div>
            </Container>
        </div>
    );
}

export default LoginPage;
