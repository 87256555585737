import { useProject } from "../../project/ProjectContext";
import { useAuth } from "../../auth/AuthContext";
import React, { useEffect, useState } from "react";
import PresaleJobActionsTable from "./PresaleJobActionsTable";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";

const emptyPresaleJobAction = {
    id: null,
    job_configuration_id: null,
    sources_chats: [],
    sources_actions: [],
}

const PresaleJobs = () => {
    const { project } = useProject();
    const [ openDialog, setOpenDialog ] = useState(false);
    const { dbUser } = useAuth();
    const [ jobActions, setJobActions ] = useState([]);
    const [ jobConfigurations, setJobConfigurations ] = useState([]);
    const [ formValues, setFormValues ] = useState(emptyPresaleJobAction);
    const [ chats, setChats ] = useState([]);
    const [ actions, setActions ] = useState([]);

    const transformAction = (action) => {
        action.name = action.comment;
        if (action.sources_str) {
            action.sources_str = JSON.parse(action.sources_str);
            if (action.sources_str.sources_chats) {
                action.sources_chats = action.sources_str.sources_chats;
            }
            if (action.sources_str.sources_actions) {
                action.sources_actions = action.sources_str.sources_actions;
            }
        }
        return action;
    }

    useEffect(() => {
        if (project) {
            const apiUrl = process.env.REACT_APP_API_URL;
            fetch(`${apiUrl}/job-actions/source/project-${project.id}/presale`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.length > 0) {
                        const transformedData = data.map((action) => transformAction(action));
                        setJobActions(transformedData);                
                    }
                })  
                .catch((error) => console.error('Failed to fetch job actions:', error));
            
            fetch(`${apiUrl}/job-configurations/context/presale`)
                .then((response) => response.json())
                .then((data) => setJobConfigurations(data))
                .catch((error) => console.error('Failed to fetch job configurations:', error));
            
            fetch(`${apiUrl}/chats?presale_id=${project.id}`)
                .then((response) => response.json())
                .then((data) => setChats(data))
                .catch((error) => console.error('Failed to fetch chats:', error));

            fetch(`${apiUrl}/job-actions/source/all-completed-for-presale/${project.id}`)
                .then((response) => response.json())
                .then((data) => setActions(data))
                .catch((error) => console.error('Failed to fetch sources:', error));
        }
    }, [project]);

    useEffect(() => {
        const jobActionsCompleted = jobActions.map((jobAction) => jobAction.status !== 'completed' ? 1 : 0).join('');
        if (jobActions.length > 0 && jobActionsCompleted > 0) {
            const apiUrl = process.env.REACT_APP_API_URL;
            fetch(`${apiUrl}/job-actions/source/project-${project.id}/presale`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.length > 0) {
                        const transformedData = data.map((action) => transformAction(action));
                        setTimeout(() => {
                            setJobActions(transformedData);
                        }, 3000);
                    }
                })
                .catch((error) => console.error('Failed to fetch job actions:', error));
        } 
    }, [jobActions, project]);

    const handleOpenDialog = (presaleJobAction = emptyPresaleJobAction) => {
        setFormValues(presaleJobAction);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleSubmit = () => {
        console.log('Submit');
        console.log('formValues:', formValues);
        const apiUrl = process.env.REACT_APP_API_URL;
        const method = formValues.id ? 'PUT' : 'POST';
        const url = formValues.id ? `${apiUrl}/job-actions/${formValues.id}` : `${apiUrl}/job-actions`;
        if (!formValues.job_configuration_id) {
            alert('Please select a job configuration.');
            return;
        }
        if (formValues.sources_chats.length === 0 && formValues.sources_actions.length === 0) {
            alert('Please select at least one chat or action.');
            return;
        }
        const jobConfiguration = jobConfigurations.find((jobConfiguration) => jobConfiguration.id === formValues.job_configuration_id)?.name;
        const sources = {
            sources_chats: formValues.sources_chats,
            sources_actions: formValues.sources_actions,
        }
        fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formValues,
                comment: jobConfiguration.name,
                presale_id: project.id,
                created_by: dbUser.id,
                sources_str: JSON.stringify(sources),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                const transformedData = transformAction(data);
                setJobActions((jobActions) => {
                    const index = jobActions.findIndex((jobAction) => jobAction.id === transformedData.id);
                    if (index >= 0) {
                        jobActions[index] = transformedData;
                    } else {
                        jobActions = [...jobActions, transformedData];
                    }
                    return [...jobActions];
                });
                handleCloseDialog();
            })
            .catch((error) => console.error('Failed to save job action:', error));
    }

    const handleSelectJobAction = (jobAction = null) => {
        if ( openDialog ) {
            setFormValues(emptyPresaleJobAction);
            handleCloseDialog();
        } else {
            console.log('Selected job action:', jobAction);
            const editFormValues = {
                id: jobAction.id,
                job_configuration_id: jobAction.job_configuration_id,
                sources_chats: jobAction.sources_chats,
                sources_actions: jobAction.sources_actions,
            }
            setFormValues(editFormValues);
            handleOpenDialog(editFormValues);
        }
    }

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            if (checked) {
                formValues[name].push(value);
            } else {
                formValues[name] = formValues[name].filter((id) => id !== value);
            }
        } else {
            formValues[name] = value;
        }
        setFormValues({
            ...formValues,
        });
    };

    const handleDeleteJobAction = (jobActionId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/job-actions/${jobActionId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Job action deleted successfully');
                    setJobActions((jobActions) => jobActions.filter((jobAction) => jobAction.id !== jobActionId));
                } else {
                    console.error('Failed to delete job action:', response.statusText);
                }
            })
            .catch((error) => console.error('Error deleting job action:', error));
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog(emptyPresaleJobAction)}
                style={{ marginBottom: '1rem' }}
            >
                + New Analysis Job
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{formValues?.id ? 'Preview Job' : 'Add New Analysis Job'}</DialogTitle>
                <DialogContent>
                    {jobConfigurations.length === 0 || chats.length === 0 ? 'No job configurations or no information available.' : (<>
                        <TextField
                            label="Job Name"
                            variant="outlined"
                            name="job_configuration_id"
                            value={formValues?.job_configuration_id || ''}
                            style={{ marginBottom: '1em', marginTop: '1em'}}
                            select
                            fullWidth
                            required
                            onChange={handleChange}
                            disabled={formValues?.id}
                        >
                            <MenuItem 
                                key="empty-job-configuration"
                                value=""
                            >
                                Select a job configuration
                            </MenuItem>
                            {jobConfigurations.map((jobConfiguration) => (
                                <MenuItem 
                                    key={jobConfiguration.id} 
                                    value={jobConfiguration.id}
                                >
                                    {jobConfiguration.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {chats.length > 0 && chats.map((chat) => (
                        <Table key={`table-chat-${chat.id}`}>
                            <TableHead key={`thead-chat-${chat.id}`}>
                                <TableRow key={`head-row-chat-${chat.id}`}>
                                    <TableCell key={`head-row-cell-${chat.id}`} colSpan={2} >
                                        <b>
                                            {chat['description']}
                                        </b>
                                        {chat.chat_date && <div>{chat.chat_date}</div>}
                                    </TableCell>
                                </TableRow>
                            </TableHead>  
                            <TableBody key={`tbody-chat-${chat.id}`}>
                                <TableRow key={`source-chat-${chat.id}`}>
                                    <TableCell>
                                        Original text
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch 
                                            variant="standard"
                                            value={chat.id}
                                            name="sources_chats"
                                            onChange={handleChange}
                                            checked={
                                                formValues.sources_chats.length > 0 && formValues.sources_chats.includes(chat.id)
                                            }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={formValues?.id}
                                        />
                                    </TableCell>
                                </TableRow>
                                {actions.map((action) => (
                                    <>
                                        {action.sources_str === chat.id && <TableRow key={`source-action-${action.id}`}>
                                            <TableCell>
                                                {action?.comment} {action.created_at && `[${action.created_at.substring(0,16).replace('T', ', ')}]`}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Switch 
                                                    variant="standard"
                                                    value={action.id}
                                                    name="sources_actions"
                                                    onChange={handleChange}
                                                    checked={
                                                        formValues.sources_actions.length > 0 && formValues.sources_actions.includes(action.id)
                                                    }
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={formValues?.id}
                                                />
                                            </TableCell>
                                        </TableRow>}
                                    </>
                                ))}
                            </TableBody>                         
                        </Table>
                        ))}
                        {actions.length > 0 && actions.map((action) => {
                            if (action?.job_configuration?.job_context !== 'presale') {
                                return <></>
                            }
                            return (
                                <Table key={`table-presale-${action.id}`}>
                                    <TableBody key={`tbody-presale-${action.id}`}>
                                        <TableRow key={`source-action-${action.id}`}>
                                            <TableCell>
                                                <b>
                                                    {action?.comment}
                                                </b>
                                                {action.created_at && <div>{action.created_at.substring(0,16).replace('T', ', ')}</div>}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Switch 
                                                    variant="standard"
                                                    value={action.id}
                                                    name="sources_actions"
                                                    onChange={handleChange}
                                                    checked={
                                                        formValues.sources_actions.length > 0 && formValues.sources_actions.includes(action.id)
                                                    }
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={formValues?.id}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )
                        })}
                    </>)}
                </DialogContent>
                {formValues?.id ? (
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                ) : (<DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>)}
            </Dialog>
            <PresaleJobActionsTable 
                jobActions={jobActions}
                jobConfigurations={jobConfigurations}
                handleSelectJobAction={handleSelectJobAction}
                handleDeleteJobAction={handleDeleteJobAction}
            />
        </>
    );
}

export default PresaleJobs