import { useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const RequireAuth = ({ children }) => {
  const { user, dbUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
      if (!user || (dbUser && !dbUser.is_active)) {
          navigate('/login?redirect=' + decodeURI(location.pathname));
      }
    }, [user, dbUser, location, navigate]);
  return children;
};

export default RequireAuth;
