import React from 'react';
import UserList from '../components/users/UserList';

const UsersPage = () => {
    return (
        <div>
            <h1>Users</h1>
            <UserList />
        </div>
    );
}

export default UsersPage;