// NotFoundPage
import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
        <h1>404 Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFoundPage;
